// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-message-sent-js": () => import("./../src/pages/message-sent.js" /* webpackChunkName: "component---src-pages-message-sent-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-list-js": () => import("./../src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-whitepaper-js": () => import("./../src/templates/whitepaper.js" /* webpackChunkName: "component---src-templates-whitepaper-js" */),
  "component---src-templates-whitepaper-list-js": () => import("./../src/templates/whitepaper-list.js" /* webpackChunkName: "component---src-templates-whitepaper-list-js" */)
}

